.index-slide-show-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);
	margin-top: var(--page-margin-top);



	@media (max-height: 550px) {
		min-height: 680px;

	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				font-size: 36px;
				color: var(--color-primary);
				height: 54px;
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

		}
	}

	.video-block {
		width: 100%;
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);
		position: relative;
		overflow: hidden;

		/* @media (max-width: 767px) {
			position: static;
		} */

		@media (max-width: 767px) {
			height: auto;
			min-height: auto;
		}

		@media all and (min-width: 768px) and (max-width: 991px) {
			bottom: 0;
		}

		.video-inner {
			min-height: 100%;
			min-width: 100%;
			height: var(--index-slide-show-height);
			width: 100%;

			@media (max-width: 767px) {
				min-height: auto;
				min-width: auto;
				height: auto;
			}
		}

		video {
			min-height: 100%;
			min-width: 100%;
			position: absolute;
			bottom: -80px;

			@media (max-width: 1199px) {
				left: -20%;
			}

			@media (max-width: 991px) {
				left: -15%;
			}



			@media (max-width: 767px) {
				position: static;
				width: 100%;
				height: auto;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				left: -90%;
			}

			@media all and (min-width: 992px) and (max-width: 1040px) {
				left: -65%;
			}

			@media all and (min-width: 1041px) and (max-width: 1199px) {
				left: -50%;
			}

			@media all and (min-width: 1200px) and (max-width: 1280px) {
				left: -45%;
			}

			@media all and (min-width: 1281px) and (max-width: 1320px) {
				left: -42%;
			}

			@media all and (min-width: 1321px) and (max-width: 1500px) {
				left: -400px;
			}

			@media all and (min-width: 1400px) and (max-width: 1500px) {
				top: -330px;
			}

			/* @media (max-width:574px) {
				position: relative;
				left: -120px;
			}

			@media (max-width:767px) {
				min-height: auto;
				height: 100%;
				min-width: 700px;
			} */
		}
	}


	h1 {
		/* position: absolute; */
		top: 40%;
		z-index: 1;
		width: 100%;
		text-align: center;
		color: #fff;
		font-size: 65px;
		text-shadow: 2px 2px 0 rgb(0 0 0 / 60%);

		@media (max-width: 767px) {
			font-size: 40px;
			top: 50%;
			padding: 0 15px;
		}

		@media (max-height: 550px) {
			font-size: 25px;
			top: 270px;
		}

		@media all and (min-width: 768px) and (max-width: 992px) {
			font-size: 60px;
			top: 45%;
		}
	}

	h2 {
		color: #fff;
		font-size: 35px;
		text-shadow: 2px 2px 0 rgb(0 0 0 / 60%);
	}

	.owl-carousel {
		z-index: unset;
	}


	.index-slide {
		height: var(--index-slide-show-height);
		margin-top: var(--index-slide-show-margin-top);

		.item {
			height: var(--index-slide-show-height);
			overflow: hidden;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.slide-item {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);
			position: relative;
			background-repeat: no-repeat;
			background-position: top center;
			background-size: cover;
		}

		.picture {
			height: var(--index-slide-show-height);
			min-height: var(--index-slide-show-height);

			@media (max-height: 550px) {
				min-height: 680px;

			}

			.img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	.carousel-item {
		height: var(--index-slide-show-height);
		overflow: hidden;

		@media (max-height: 550px) {
			min-height: 680px;

		}
	}

	.carousel-item img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	.slide1 {
		background-image: url(../img/slide1-1980.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.hero-header {
		position: absolute;
		top: 20vh;
		font-family: 'Poppins', cursive;
		width: 90%;
		z-index: 3;
		margin: 0 auto;
		left: 0;
		right: 0;


		@media (max-width: 767px) {
			margin: 0 auto;
			left: 0;
			right: 0;
		}

		@media (max-height: 550px) {
			top: 140px;

		}



		.hero-text {
			text-align: center;
			max-width: 1000px;
			margin: 0 auto;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 0;
			padding: 15px;


			.hero-headline {
				width: 100%;
			}

			h1,
			.h1 {

				font-size: 80px;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				line-height: 70px;
				font-weight: 400;
				margin-bottom: 10px;
				padding: 0 15px;
				font-family: 'Turbinado Pro';

				@media (min-width: 1600px) {
					/*font-size: 80px;*/
					line-height: 70px;
					margin-bottom: 10px;
				}

				@media (max-width: 1140px) {
					font-size: 60px;
				}

				@media (max-width: 992px) {
					font-size: 60px;
					line-height: 50px;
				}

				@media (max-width: 767px) {
					font-size: 34px;
					line-height: 30px;

				}

				@media (max-height: 550px) {
					font-size: 36px;
					line-height: normal;
				}

			}

			h2,
			.h2 {
				color: #fff;
				font-size: 26px;
				text-shadow: rgb(0 0 0 / 50%) 0 0.1rem 1rem;
				font-weight: 300;
				padding: 0 15px;
				line-height: 30px;

				@media (max-width: 992px) {
					font-size: 20px;
					line-height: 25px;
				}

				@media (max-width: 767px) {
					font-size: 16px;
					line-height: 20px;
				}

				@media (max-height: 550px) {
					font-size: 18px;
					line-height: normal;
				}
			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.5rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);

				@media (min-width: 1600px) {
					font-size: 2.5rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 2.0rem;
				}

				@media (max-width: 575px) {
					font-size: 1.5rem;
				}
			}
		}

	}
}