.inner-banner {
	height: 390px;
	position: relative;
	overflow: hidden;

	@media (max-width:992px) {
		height: 320px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:after {
		height: 80px;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		display: block;
		background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
		background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.8) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#cc000000', GradientType=0);
		content: "";
		opacity: 0.6;
	}

	.banner-text {
		padding: 40px;
		top: 90px;
		position: absolute;
		left: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
		width: 80%;

		p {
			margin-bottom: 0;
		}
	}

	.container {
		position: relative;
	}
}

.page-header {
	h1 {
		position: relative;
		top: 13px;

		small {
			font-family: var(--d-font);
			font-size: 21px;
			color: #ccc;
			position: relative;
			top: -10px;
		}
	}
}

.single-unit {
	.img-content {
		height: 260px;
		overflow: hidden;

		display: block;

		img {
			object-fit: cover;
			height: 100%;
			width: 100%;
		}
	}

	.unit-cont-bl {
		min-height: 190px;
		border: 1px solid var(--color-grey-normal);
		background-color: var(--white);
		display: flex;
		flex-direction: column;
		min-height: 130px;
		justify-content: space-between;
		padding: 10px;
	}

	.label-container {
		text-align: right;
	}

	.unit-title {
		font-size: 18px;
		color: #000;
		color: var(--black);
	}
}

.facity-heading {
	padding-top: 20px;
	padding-bottom: 10px;
}

.featur-bl {
	border: 1px solid #ddd;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	border-radius: 10px;
	overflow: hidden;

	@media (max-width:992px) {
		margin-bottom: 25px;
	}

	img {
		width: 100%;
	}
}


.form-strip {
	.contact-form {


		label {
			color: var(--black);
			font-weight: bold;
			font-size: 14px;
			text-transform: uppercase;
			margin-bottom: 0;
			padding-bottom: 10px;

			&:before {
				top: 2px;
			}

		}

		.checkbox {
			label {
				text-transform: inherit;
				font-weight: normal;
			}

			a {
				color: var(--black);
				font-size: 14px;

			}
		}

		.conact-btn {
			width: 100%;
			bottom: 0;
			margin-top: 35px;

			.btn {
				padding: 11px 30px 15px;
				box-shadow: 2px 1px 10px rgba(0, 0, 0, .2);
				font-size: 20px;

				@media (max-width:992px) {
					font-size: 18px;
				}
			}
		}

		.form-control {
			border: none;
			border-bottom: 2px solid #f2f2f2;
			text-transform: uppercase;
			margin-bottom: 30px;
			border-radius: 0;
			box-shadow: inherit;
			padding: 0;
			background-color: transparent;
		}
	}

	.unit-map {
		height: 400px;
		width: 100%;

		@media (max-width:992px) {
			margin-bottom: 20px;
		}
	}
}