.widget {
	background: #fff !important;
	border-radius: 7px;
	box-shadow: 0px 0px 22px -6px rgb(0 0 0 / 5%);
}

.widget-header {
	background-color: var(--widget-header-bg-color);
	border: 1px solid var(--widget-border-color);
	padding: 10px;
	margin-bottom: 0;
	border-bottom: none;
	font-size: var(--h2-font-size);
	font-weight: var(--h2-font-weight);
	text-decoration: var(--h2-text-decoration);
	text-transform: var(--h2-text-transform);
	color: var(--widget-header-title-font-color);
	font-family: var(--title-font-family-main);
	border-radius: 0px;
	background: transparent;
	border: none;
	border-bottom: 1px solid #ccc;
}

.widget-block {
	background-color: transparent;
	padding: 15px;
	border: 0px solid var(--widget-border-color);
	border-radius: 0 0 5px 5px;


	.title {
		font-size: var(--h3-font-size);
		font-weight: var(--h3-font-weight);
		text-decoration: var(--h3-text-decoration);
		text-transform: var(--h3-text-transform);
		color: var(--widget-block-title-font-color);
		margin-bottom: 1rem;
		position: relative !important;
		padding-left: 0 !important;
	}

	.unit-map {
		width: 100%;
		height: 380px;
		position: relative;
	}
}

.hidden {
	display: none;
}

.fa-charging-station {
	color: green;
}