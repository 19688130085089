.unit-title-v1 {
	.stars {
		color: var(--color-stars);
		font-size: var(--font-size-md);
		vertical-align: top;
	}

	.dtv {
		a {
			font-size: 14px;
			vertical-align: top;
			font-weight: 400;
		}
	}
}