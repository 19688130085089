.faq-v2 {
	margin-top: var(--page-margin-top);

	.faq-links {
		margin: 40px 0 40px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		background: #fff;
		padding: 15px;
		border-radius: 10px;
		box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);

		li {
			padding: 10px 15px;
			width: 20%;

			@media (max-width: 1499px) {
				width: 33.33%;
			}

			@media (max-width: 1199px) {
				width: 50%;
			}

			@media (max-width: 767px) {
				width: 100%;
			}



			a {
				text-align: center;
				width: 100%;
				font-size: 16px;
			}
		}
	}

	.card {
		background-color: transparent;
		border: 1px solid var(--color-grey-normal);

		.card-header {
			background-color: var(--faq-title-bg-color);
			border-bottom: none;
			padding: 0px 5px;
		}

	}

	.btn-link {
		font-weight: bold;
		color: var(--faq-title-font-color);
		text-decoration: none;
		width: 100%;
		text-align: left;
		font-size: var(--font-size-main);

		&:hover {
			color: var(--faq-title-font-color);
			text-decoration: none;
		}
	}

}