.sparwochen {
	.blogcontent {
		p a {
			background-color: var(--btn-primary-bg-color);
			font-size: var(--font-size-md);
			padding: 6px 12px;
			color: var(--btn-secondary-font-color);
			border-radius: var(--btn-border-radius);
			border-color: var(--btn-secondary-border-color);
			display: inline-block;
			text-decoration: none;

			&:hover {
				background-color: var(--btn-primary-bg-color-hover);
				border-color: var(--btn-primary-bg-color-hover);
				color: var(--btn-primary-font-color-hover);
			}
		}
	}
}