.unit-book-now-v1 {
	position: relative;

	@media(max-width:992px) {
		margin-top: 30px;
	}

	@media(min-width:992px) {

		border-radius: 10px;
		position: relative;
		margin-bottom: 10px;
		z-index: 12;
		border-radius: 7px;
		padding: 10px 0 0;
	}

	&.affix {
		position: sticky;
		top: 0;
		z-index: 2;
		min-height: 200px;
		top: 180px;

		@media all and (min-width: 992px) and (max-width: 1200px) {
			top: 20px;
		}

		&:before {
			display: block;
			content: " ";
			width: 70%;
			height: 7px;
			background: var(--color-contrast);
			position: absolute;
			left: 0;
			border-radius: 0 0 10px 10px;
			right: 0;
			margin: 0 auto;
			top: 0;
		}
	}





	.btn-default.btn-block {
		background: transparent;
		border: none;
		border-radius: 0;
		text-decoration: underline;
		padding: 0;
		margin-top: 5px;
		color: #3a494e;

		@media (max-width:991px) {
			padding: 0.375rem 0.75rem;
			background-color: var(--btn-secondary-bg-color);

			color: var(--btn-secondary-font-color);
			border-radius: 0.25rem;
			border-color: var(--btn-secondary-border-color);
		}

		&:hover {
			color: var(--btn-default-font-color);

			@media (max-width:991px) {
				background-color: var(--btn-secondary-bg-color-hover);
			}
		}
	}

	.btn-primary {
		margin-bottom: 15px;

		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}
}