.hero-image-v1 {
	position: relative;
	height: var(--index-slide-show-height);
	min-height: var(--index-slide-show-height);
	margin-top: var(--page-margin-top);

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.picture {
		height: var(--index-slide-show-height);
		min-height: var(--index-slide-show-height);

		@media (max-height: 600px) {
			min-height: 680px;

		}

		.img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.hero-header {
		display: none;
		position: absolute;
		bottom: 50vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 1500px) {
			font-size: 3rem;
		}

		@media (max-width: 992px) {
			top: 40px;
		}

		/*@media (max-width: 992px) {
			bottom: 38vh;
		}*/



		@media (max-height: 750px) {
			top: 40px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			/*.hero-headline {
				width: 100%;
				margin-bottom: 20px;
			}*/

			.hero-headline {
				width: 100%;
				margin-bottom: 20px;
				font-family: var(--hero-header-font-family);
				font-size: 4vw;
				color: var(--title-font-color-main);
				/*	text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);*/
				font-weight: var(--h1-font-weight);
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 55px;
					margin-bottom: 25px;
				}

				@media (max-width: 1550px) {
					font-size: 3rem;
				}

				@media (max-width: 1140px) {
					font-size: 40px;
					margin-bottom: 30px;
				}

				@media (max-width: 992px) {
					font-size: 2.8rem;
					line-height: 3rem;
				}

				@media (max-width: 576px) {
					font-size: 1.8rem;
					line-height: 2rem;
				}

				@media (max-height: 600px) {
					font-size: 2.5rem;
					line-height: 2.5rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.3rem;
				color: #54727e;
				max-width: 970px;
				margin: 0 auto;
				background: rgb(255 255 255 / 70%);
				padding: 10px;

				@media (max-width: 1550px) {
					font-size: 1.3rem;
					line-height: 1.8rem;
				}

				@media (min-width: 1600px) {
					font-size: 1.8rem;
					line-height: 2.2rem;



				}

				@media (max-width: 1140px) {
					font-size: 1.2rem;
					line-height: 1.8rem;
				}

				@media (max-width: 992px) {
					font-size: 1.7rem;
				}

				@media (max-width: 575px) {
					font-size: 1rem;
					line-height: 1.5rem;
				}
			}
		}

	}
}