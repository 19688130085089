.unit-service-v1 {
	background-color: var(--color-grey-light);
	margin-top: 15px;
	border-radius: 8px;

	.phone-link {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.title {
		font-weight: 700;
	}

	.image {
		img {
			width: 100%;
			object-fit: cover;
			border-radius: var(--border-radius);

		}
	}

	.info {
		padding: 10px;
	}

	.fa-sharp {
		padding-right: 5px;
	}

	a {
		color: var(--font-color-main);

		&:hover {
			text-decoration: underline;
		}
	}
}