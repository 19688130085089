.contact-sidebar-v1 {
	padding: 0 !important;
	position: relative;

	&:before {
		display: block;
		content: " ";
		width: 70%;
		height: 7px;
		background: #b7bb3e;
		background: var(--color-contrast);
		position: absolute;
		left: 0;
		border-radius: 0 0 10px 10px;
		right: 0;
		margin: 0 auto;
		top: 0;
	}

	.lage {
		background-color: #fff;
		border-radius: 10px;
		margin-bottom: 40px;
		box-shadow: 0 0 22px -6px rgb(0 0 0 / 5%);

		.lage-map {
			padding: 30px;
		}

		.contact-map {
			width: 100%;
			height: 300px;

		}
	}

	.contact-sidebar-info {
		background-color: #fff;
		border-radius: 10px;
		position: relative;
		box-shadow: 0 0 22px -6px rgb(0 0 0 / 5%);

		&:before {
			display: block;
			content: " ";
			width: 70%;
			height: 7px;
			background: #b7bb3e;
			background: var(--color-contrast);
			position: absolute;
			left: 0;
			border-radius: 0 0 10px 10px;
			right: 0;
			margin: 0 auto;
			top: 0;
		}

		.contact-sidebar-info-inner {
			padding: 30px 30px 20px 30px;

			img {
				border-radius: 10px;
			}
		}
	}

	h3,
	.h3 {
		font-size: var(--h4-font-size);
		font-weight: 500;
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding: 25px 25px 20px 35px;
		border-bottom: 1px solid #ccc;
		position: relative;
	}



	.opening {
		position: relative;
		padding-left: 40px;

		i.fa {
			color: var(--color-white);

			background-color: var(--color-secondary);
			padding: 5px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: -webkit-inline-box;
			display: inline-flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgb(0 0 0 / 18%);
			font-size: 16px;
		}
	}

	.contact-bl {
		display: flex;
		margin-bottom: 15px;
		position: relative;
		padding-left: 40px;
		min-height: 40px;
		align-items: center;
		font-size: var(--font-size-md);

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: var(--color-black);

			&:hover {
				color: var(--color-primary);
			}
		}


		i {
			color: var(--color-white);
			background-color: var(--color-secondary);
			padding: 5px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgba(0, 0, 0, .18);
			font-size: 16px;
		}
	}


	.openings {
		div {
			margin-top: 10px;
			font-size: var(--font-size-md);

		}
	}
}