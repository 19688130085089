.contact-form-v1 {
	padding-bottom: 40px !important;
	background: #fff;
	border-radius: 10px;
	padding-top: 40px !important;
	box-shadow: 0 0 22px -6px rgb(0 0 0 / 5%);

	.checkbox label {
		vertical-align: inherit;
	}

	.red {
		color: var(--color-red);
	}


}

.contact-form {
	.section-header {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: -20px;
	}
}