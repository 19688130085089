.unit-card-v2 {
	.unit-icon-rating {
		height: auto;
		position: absolute;
		padding: 10px;
		margin: -25px 20px 0;
		background-color: #fff;
		border-radius: 5px;
		box-shadow: 0 0 10px 0 rgb(6 30 98 / 8%);
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
		left: 0;
		right: 0;

		.rating {
			font-size: 10px;
			display: flex;
			-webkit-box-align: center;
			align-items: center;

			.ratings {
				margin-right: 5px;
			}


			.fa {
				color: var(--color-orange);
				font-size: 10px;

				&.fa-star-o {
					display: none;
				}
			}
		}

	}

	.content {
		padding: 10px;
		display: flex;
		flex-direction: column;
		min-height: 190px;
		justify-content: space-between;
		border: 1px solid var(--color-grey-normal);
		padding-top: 40px;
		border-radius: 0 0 5px 5px;
		background-color: var(--white);

		.alternatives {
			margin-top: 10px;

			@media (min-width:768px) {
				min-height: 265px;
			}

			.invalid {
				text-decoration: line-through;
			}

			div {
				display: none;

				&:nth-child(-n+4) {
					display: block;
				}
			}
		}

		.region-type {
			font-size: var(--font-size-md);
		}

		.fa {
			padding-right: 5px;
		}

		.fa-map-marker-alt {
			padding-left: -10px;
		}

		.label-container {
			display: flex;
			justify-content: space-between;
			flex-direction: row-reverse;
			align-items: center;

			.rating {
				max-width: 200px;
				width: 100%;

				div {
					display: flex;
					align-items: center;
				}

				.fa,
				.fas {
					color: var(--color-star-rating);
				}
			}

			.label {
				padding: 5px 10px;
				color: white;
				border-radius: 5px;
				font-weight: 500;
				width: fit-content;
				font-weight: 500;
				line-height: 25px;
				font-size: var(--font-size-md);
			}

			.btn {
				font-size: var(--font-size-md);
			}

			.customer-rating {
				background: var(--color-secondary);
				font-weight: 500;
			}
		}


	}

	.comodities {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}


	.comodity {
		padding: 5px;
	}

	.image-wrap {
		position: relative;


		&:hover {
			img {
				filter: brightness(0.7);
			}
		}

		img {
			width: 100%;
			transition: 0.5s;
			border-radius: 5px 5px 0 0;
		}

		.stars {
			position: absolute;
			bottom: 10px;
			right: 10px;
			color: var(--color-stars);
			background-color: white;
			padding: 9px;
			border-radius: 5px;
			display: flex;
			align-items: center;
		}

		.rules {
			position: relative;
			bottom: 0;
			left: 0;
			background-color: white;
			padding: 0;
			border-radius: 5px;
			color: var(--font-color-main);
			display: flex;

			i {
				width: 35px;
				height: 35px;
				position: relative;
				margin-right: 5px;
				background-color: var(--color-primary);
				display: flex;
				-webkit-box-pack: center;
				justify-content: center;
				-webkit-box-align: center;
				align-items: center;
				color: #fff;
			}

			.fa-slash {
				width: auto;
				height: auto;
				position: absolute;
				margin: 0;
				background: transparent;
				bottom: 0;
				left: 6px;
				top: 3px;
			}
		}

		.favorite {
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 1.5em;
		}
	}


	.ribbon {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
	}

	.ribbon span {
		font-size: 13px;
		font-weight: bold;
		color: #fff;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: var(--color-red);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px;
		left: -21px;
	}

	.lineheight {
		min-height: 1.8em;
	}
}