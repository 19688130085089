.about-us-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/team-banner-new01.jpg);

		@media (max-width: 767px) {
			height: 132px;
			background-position: center !important;
		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;

		.member {
			background-color: var(--color-white);
		}

		.member-image {
			height: 100%;
			border-radius: 100%;
		}
	}

	.blog-card-v1 {
		.image {
			img {
				max-height: 360px !important;
				width: 100%;
				height: 100%;
				object-fit: cover;
				padding: 0 !important;
				border: none !important;
			}

		}

		.title {
			margin-bottom: 1rem;
		}
	}

	.blogcontent {
		img {

			width: 100%;
			height: auto%;
			object-fit: cover;
			padding: 0 !important;
			border: none !important;
		}

		p {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}

		p a {
			background-color: var(--btn-primary-bg-color);
			font-size: var(--font-size-md);
			padding: 6px 12px;
			color: var(--btn-secondary-font-color);
			border-radius: var(--btn-border-radius);
			border-color: var(--btn-secondary-border-color);
			display: inline-block;
			text-decoration: none;

			&:hover {
				background-color: var(--btn-primary-bg-color-hover);
				border-color: var(--btn-primary-bg-color-hover);
				color: var(--btn-primary-font-color-hover);
			}
		}
	}

	.message {
		color: red;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);
		min-height: 210px !important;

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}


}