.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		border-top: 1px solid var(--footer-border-top-color);

		.text-muted {
			color: var(--color-white) !important;

		}

		.para {
			max-width: 450px;
		}

		padding-left: 5vw;
		padding-right: 5vw;

		.headline {
			font-size: 20px;
			color: var(--color-white);
			text-transform: uppercase;
			font-family: var(--title-font-family-main);
		}


		.logo {
			max-width: 390px;
			height: auto;
			margin-top: -13px;
			padding-bottom: 20px;
			width: 100%;
		}

		.openings {

			div {
				padding-top: 10px;
			}
		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--color-green);

				}
			}

		}

		.address-box {
			ul {
				li {
					position: relative;
					margin: 0 0 15px 0;
					padding: 0 0 0 30px;

					i {
						position: absolute;
						color: #fff !important;
						left: 0;
						top: 5px;
					}
				}
			}
		}




		#button-addon1 {
			color: #ffc371;
			padding: 0.275rem 0.75rem;
		}

		.btn-link {
			background-color: var(--btn-primary-border-color);

		}

		i {
			color: var(--color-white);
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.opening {
		position: relative;
		padding-left: 25px;

		i.fa {
			position: absolute;
			left: 0;
			top: 6px;
			color: var(--color-green);
		}
	}

	.logos {
		@media (max-width:767px) {
			margin-bottom: 20px;
		}
	}

	.logo-small {
		background-color: var(--color-white);
		display: inline-block;
		padding: 5px;
		border-radius: 5px;
		margin-right: 0;
		margin-top: 5px;

		&:first-child {
			/*background-color: grey;*/
			background-color: transparent;
			padding: 0;

			img {
				width: 150px;
			}
		}

		img {
			width: 70px;
		}

		&.width110 {
			img {
				width: 110px;
			}
		}

		&.width150 {
			img {
				width: 150px;
			}
		}
	}

	.copyright {
		background-color: var(--color-white);

		.flex-bl {


			>img {
				height: 80px;

				@media (max-width:992px) {
					height: 50px;
				}
			}
		}

		ul {
			margin-bottom: 0;

			@media (min-width:768px) {
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
			}

			@media all and (min-width: 769px) and (max-width: 1199px) {
				padding-right: 110px;
			}

			@media all and (min-width: 1200px) and (max-width: 1350px) {
				padding-right: 80px;
			}

			@media all and (min-width: 1500px) and (max-width: 1600px) {
				padding-right: 50px;
			}


			li {
				margin-right: 15px;

				@media (max-width:767px) {
					margin-right: 0;

				}

				&:last-child {
					margin-right: 0;
				}

				a {
					color: var(--color-grey2);
					font-size: 16px;
				}
			}
		}
	}

	.flex-bl {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width:767px) {
			flex-direction: column;
		}
	}

}