.search-page-layout-v2 {

	/* margin-top = height of nav */
	@media (max-width:992px) {
		margin-top: 100px;
	}

	@media (max-width:768px) {
		margin-top: 126px;
	}

	@media (max-width:557px) {
		margin-top: 126px;
	}

	/* margin-top = height of nav + height of .search-panel */
	@media (min-width:993px) {
		margin-top: 178px;
	}


	.search-panel {
		height: 80px;
		background-color: var(--color-secondary);

		@media (max-width:992px) {
			position: unset;
			height: auto;
			padding: 15px;
		}

		@media (min-width:993px) {
			top: 98px;
			position: fixed;
			z-index: 20;
		}

		/*@media (max-width:767px) {
			position: unset;
			height: auto;
			padding: 15px;
		}*/


	}

	.result-pane {
		background-color: var(--color-gray-light-bg);
		width: 100%;

		@media (max-width:768px) {
			width: 100%;
		}

		@media (min-width:769px) {
			width: 60%;
		}

		@media (min-width:1200px) {
			width: 50%;
		}

		.close {
			font-size: 18px !important;
			color: #000;
		}

		.more-btn-panel {
			margin-bottom: 35px;
		}

	}

	.map-pane {

		@media (max-width: 768px) {
			display: none;
			width: 0px;
		}

		@media (min-width: 769px) {
			width: 100%;
		}

		@media (max-width:1199px) {
			width: 40%;
		}


		@media (min-width:1200px) {
			width: 50%;
		}


		.header {
			display: none;
		}

		.map {
			position: -webkit-sticky;
			position: sticky;
			z-index: 14;

			@media (max-width:992px) {
				/* the same height as .search-panel */
				top: 80px;


			}

			@media (min-width:993px) {
				/* height .search-panel + nav-height */
				top: 178px;

			}



		}

	}

	.hidden {
		display: none !important;
	}

	.mob-input-bl {

		span {
			box-shadow: 0 0 10px rgb(0 0 0 / 20%);
			background-color: #fff;
			background-color: var(--color-white);
			border-radius: 3px;
			font-size: 14px;
			display: block;
			padding: 13px 5px 13px 15px;
		}

	}

	.close-filter {
		@media (max-width:992px) {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, .5);
			z-index: 10;
		}

		.fa {
			position: absolute;
			top: 20px;
			right: 20px;
			font-size: 30px;
			color: var(--color-white);
		}
	}

	.map-view {
		.result-pane {
			display: none;
		}

		.map-pane {
			width: 100%;
			display: block;
		}
	}

	.no-google-search-map {
		height: 200px;
		margin-left: 20px;
		margin-right: 20px;
		position: relative;
	}
}