/*.vo-index-body {

	.navbar-v1.affix,
	.navbar-v1 {
		position: relative;
	}
}*/

.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);

	/*@media (max-width:1200px) {
		position: absolute;
	}*/

	.input-search-unit {
		padding: 2px 15px 7px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
			background-color: var(--color-dark-blue);
			border-color: var(--color-dark-blue);
			color: var(--color-white);
		}
	}

	.top-nav {
		padding: 13px 0 0;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			@media (max-width: 767px) {
				justify-content: center;
			}

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					@media (max-width: 767px) {
						font-size: 0;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i {
						font-size: var(--font-size-md);
						margin-right: 5px;
						color: var(--color-green);

						@media (max-width: 767px) {}
					}
				}
			}
		}

		.fav-count {

			.sub-count {
				-moz-border-radius: 50%;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				display: block;
				position: absolute;
				top: -7px;
				left: 2px;
				width: 18px;
				height: 18px;
				background: var(--color-primary);
				color: var(--color-white);
				text-align: center;
				font-size: 12px;
				line-height: 18px;
			}
		}

	}

	.lang-dropdown {
		a {
			font-size: var(--top-nav-font-size) !important;

		}

		.flag-de,
		.flag-en {
			background-repeat: no-repeat;
			display: inline-block;
			background-size: contain;
			padding-left: 23px;
			background-size: 18px;
			background-position: 0 6.5px;
		}

		.dropdown-menu {

			.sub-nav {
				ul {
					li {
						a {
							padding-left: 40px !important;
							display: block;

							&.flag-de,
							&.flag-en {
								background-position: 12px 11px;
							}
						}
					}
				}
			}
		}


		.dropdown-toggle:after {
			position: absolute;
			top: 10px;
		}

		.flag-de {
			background-image: url(RESOURCE/img/flag-de.jpg);
		}

		.flag-en {
			background-image: url(RESOURCE/img/flag-en.jpg);
		}



		.dropdown-toggle {
			cursor: pointer;
		}

		.dropdown-menu {
			min-width: 120px !important;
			position: absolute !important;

			@media (max-width: 767px) {
				top: 30px !important;
			}

			@media all and (min-width: 768px) and (max-width: 992px) {
				top: 30px !important;
				left: -35px !important;
			}


			ul {
				padding: 0;

				li {
					width: 100%;
					font-size: 16px;

					a {
						font-size: 14px !important;
						padding: 5px 15px !important;
						cursor: pointer;
					}
				}
			}
		}
	}

	.header-main {

		.header-logo {
			position: absolute;
			top: 11px;
			z-index: 20;

			@media all and (min-width: 769px) and (max-width: 1200px) {
				top: 22px;
			}

			@media all and (min-width: 1201px) and (max-width: 1500px) {
				top: 11px;
			}

			.logo {
				width: 290px;
				height: auto;

				@media (max-width:1200px) {
					width: 220px;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					width: 230px;
				}

				@media (max-width:768px) {
					width: 200px;
				}

				@media all and (min-width: 1201px) and (max-width: 1500px) {
					width: 290px;
				}
			}

		}

		.main-menu {
			float: right;

			@media (max-width:1200px) {
				position: absolute;
				left: 0;
				top: 100%;
				background-color: var(--main-nav-mob-menu-bg-color);
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					display: inline-block;

					@media (max-width:1200px) {
						display: block;
						width: 100%;
					}

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 14px 1.2vW 11px;
						line-height: 1;
						display: block;
						font-weight: 400;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}

						.dropdown-toggle.no-arrow:after {
							display: none
						}

						&.mega-menu {
							position: inherit;



							.dropdown-menu {
								width: 100%;
								transform: inherit !important;
								margin-top: 48px;
								padding: 0 15px;
								background-color: #d2983afa;

								h4 {
									margin-top: 20px;
								}
							}
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;
		background-color: rgb(81 105 112 / 90%);
	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 10px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: absolute;
		right: 10px;
		text-decoration: none;
		color: var(--color-dark-blue);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	@media screen and (max-width: 1200px) {

		.top-nav {
			padding: 10px 0 8px;
			text-align: right;


			.input-search-unit {
				display: none;
			}
		}

		.header-logo {
			padding-left: 0px !important;
		}

		.nav-row {
			min-height: 60px !important;
		}

		.icon {
			display: block;
			bottom: 20px;
		}

		.dropdown-menu {
			position: relative !important;
			transform: none !important;
			width: 100%;
			border-top: none;
		}

		.main-menu {
			background-color: var(--nav-bg-color);
			padding: 5px 0;
			z-index: 50;
			display: none;
			width: 100%;
			border-bottom: 1px solid #61847a;
		}

		.search-unit-ul {
			margin: 15px 0px 0px;

			.input-search-unit {
				.input-group {
					.form-control {
						@media (max-width: 574px) {
							height: 40px !important;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 768px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;
				text-align: center;

			}

			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: absolute;
				bottom: 30px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			display: block !important;
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						@media (max-width: 767px) {
							font-size: 0;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

						i {
							font-size: var(--font-size-md);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);

							@media (max-width: 1200px) {
								color: var(--color-white);
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.drodown-menu {
								background-color: #d2983afa;
							}

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.navbarheader {
	position: fixed;
	z-index: 999;
	width: 100%;
	background: #fff;
	top: 0;
	box-shadow: 0 3px 3px -3px grey;

	@media (max-width: 992px) {
		display: none !important;
	}

	.container {
		@media all and (min-width:993px) and (max-width: 1199px) {
			max-width: 100% !important;
		}
	}

	.navbar-scr-bl {

		padding: 11px 0;

		@media all and (min-width:993px) and (max-width: 1499px) {
			padding: 20px 0;
		}

		>.row {
			align-items: center;
		}

		.logo-sticky {
			@media all and (min-width:993px) and (max-width: 1199px) {
				flex: unset;
				max-width: 20%;
			}

			.logo {
				max-width: 290px;
				width: 100%;
			}
		}

		.sticky-searchbar {

			@media all and (min-width:993px) and (max-width: 1199px) {
				flex: unset;
				max-width: 74%;
			}



			.search-form-area {
				box-shadow: none;
				border: none;

				.form-flex>label {
					display: none;
				}

				.form-btn {
					padding: 0 15px !important;

					.btn {
						height: 50px;
						font-size: 20px;

					}
				}

				.form-flex {
					padding: 0 15px;

					&.v2 {

						@media (min-width:993px) {
							width: 45%;
						}
					}

					@media (max-width:992px) {
						width: 100%;
						border-bottom: 1px solid #f4f4f4;
						border-right: none;
						padding: 6px 12px;
					}

					.fa,
					.fa-sharp {
						position: absolute;
						left: 10px;
						top: 11px;
					}

				}

				.datepicker-trigger {
					img {

						right: 10px;
						top: 17px;

					}
				}

				.form-flex-area {
					position: relative;

					img {
						right: 1px;
						top: 1px;
						padding: 12px;
						width: 35px;
						height: 48px;
					}
				}

				.form-btn {
					.fa {
						color: var(--color-white);
					}

					.btn {
						&:hover {
							.fa {
								color: var(--color-white);
							}
						}
					}

				}

				.form-flex {

					border: none;

					.fa {
						top: 17px;
						font-size: 17px;
					}

					.form-control {
						border: 1px solid #ccc !important;
						border-radius: 3px;
						min-height: 50px;
						padding: 0 0 0 30px;
						font-size: 16px;
						background-color: transparent;
						text-align: left;
					}
				}
			}

		}

		.sticky-drop-menu {
			text-align: center;

			@media all and (min-width:993px) and (max-width: 1199px) {
				flex: unset;
				max-width: 6%;
			}

			.dropdown {

				&.show {
					.icon-bar {
						&.middle-bar {
							display: none;
						}

						&.bottom-bar {
							position: relative;
							transform: rotate(45deg);
							top: -4px;
						}

						&.top-bar {
							position: relative;
							transform: rotate(-45deg);
							top: -2px;
						}
					}
				}

				.dropdown {
					.dropdown-toggle {
						&:after {
							display: none;
						}
					}

					.dropdown-menu {
						position: absolute;
						display: block;
						width: 200px;
						right: -39px;
						top: 47px !important;
						padding-top: 0;
						height: auto;
						background-color: transparent;


						ul {


							li {
								margin: 5px 0;
								width: 100%;

								a {
									font-size: 15px;
								}
							}
						}
					}
				}

				.dropdown-menu {
					border-radius: 0;
					background-color: #d2983adb;
					top: 54px;
					width: 895px;
					border: none;
					padding: 15px;
					top: 50px !important;
					height: 350px;

					@media all and (min-width: 1200px) and (max-width: 1500px) {
						top: 45px !important;
					}

					ul {
						padding: 0;
						margin: 0;
						text-align: center;

						li.dropdown {
							border-bottom: 1px solid #fff;
							padding: 0 0 10px 0;
						}

						li {
							list-style: none;
							display: inline-block;
							min-width: 120px;
							margin: 9px 20px 9px 20px;
							text-align: center;

							&.header-search {
								display: none;
							}


							a {
								color: #fff;
								text-transform: uppercase;
								text-decoration: none;
								letter-spacing: 0.09rem;
								font-weight: 400;
								font-size: 15px;
							}
						}
					}
				}
			}

			.icon-menu {
				background-color: transparent;
				border: none;
			}

			.icon-bar {
				background-color: var(--color-primary);

				&.icon-bar {
					display: block;
					width: 30px;
					height: 2px;
					border-radius: 1px;
					margin: 0px 0;

					&.middle-bar {
						margin: 5px 0;
					}
				}
			}
		}
	}
}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 60px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

	@media (min-width:1101px) {
		display: none !important;
	}
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}