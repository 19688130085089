.heading-style {
	.heading2 {
		font-family: 'Turbinado Pro';
		font-size: 45px;
		color: var(--heading-color);
		line-height: 40px;

		@media (max-width:767px) {
			font-size: 40px;
			line-height: 40px;

		}
	}

	span {
		font-family: 'IBM Plex Sans';
		font-size: 18px;
		color: var(--blue-dark-color);
		padding-left: 200px;
		text-transform: uppercase;
		font-weight: 500;

		@media (max-width:1199px) {
			padding-left: 160px;
		}

		@media (max-width:991px) {
			padding-left: 50px;
		}

		@media (max-width:767px) {
			padding-left: 0;

		}
	}
}

.vermieter-v1 {
	background: #FFFBF4;
	display: inline-block;
	width: 100%;

	.xl-none {
		display: none;

		@media (max-width:767px) {
			display: block;

		}
	}

	.xs-none {
		display: block;

		@media (max-width:767px) {
			display: none !important;

		}
	}

	a {
		&:hover {
			text-decoration: none;
			color: #dfae12;
		}
	}

	p {
		font-family: 'Open Sans';
		font-size: 16px;
	}

	.lassen-sec {
		.lassen-img-bl {
			padding-left: 70px;
			position: relative;
			margin-top: -110px;

			@media (max-width:1200px) {
				padding-left: 0;

			}

			@media (max-width:767px) {
				display: none;

			}

			img {
				width: 100%;
			}

			.img-sec {
				position: absolute;
				top: 250px;
				max-width: 260px;
				right: 0;

				@media (max-width:1500px) {
					top: 200px;
					max-width: 190px;
					right: 0;
				}

				@media (max-width:1200px) {
					top: 200px;
					max-width: 250px;
				}

				@media (max-width:991px) {
					padding-left: 0;
					top: 165px;
				}
			}

		}

		.link {
			font-weight: 700;
		}
	}

	.gmahde-sec {
		margin-top: 120px;

		@media (max-width:1500px) {
			margin-top: 80px;
		}

		.heading-style {
			padding-left: 20%;

			@media (max-width:767px) {
				padding-left: 0;
			}
		}

		.gmahde-img-bl {
			padding-left: 50px;

			@media (max-width:767px) {
				padding-left: 0;
				max-width: 230px;
			}

			img {
				max-width: 300px;
				width: 100%;
			}
		}

		.aufgehoben-block {
			h2 {
				font-family: 'Open Sans';
				font-size: 16px;
				color: var(--blue-dark-color);
				text-transform: uppercase;
			}

			.aufgehoben-list {
				border-radius: 10px;
				border: 1px solid #516870;
				background: #fff;
				padding: 30px 30px;
				padding-bottom: 15px;
				height: auto;
				max-width: 950px;

				ul {
					display: flex;
					flex-wrap: wrap;

					li {
						position: relative;

						font-family: 'Open Sans';
						font-size: 14px;
						padding: 8px 0 8px 30px;
						font-size: 15px;
						width: 50%;

						@media (max-width:1200px) {
							width: 100%;

						}


						&:before {
							content: "";
							background-image: url(RESOURCE/img/check-mark-icon.png);
							position: absolute;
							height: 20px;
							width: 20px;
							background-size: cover;
							left: 0;
							top: 8px;

						}

						&:nth-last-child(-n + 1) {
							&:before {
								content: "";
								background-image: url(RESOURCE/img/check-mark-icon-green.png);
								position: absolute;
								height: 20px;
								width: 20px;
								background-size: cover;
								left: 0;
								top: 8px;

							}
						}

					}
				}
			}
		}
	}

	.premium-sec {
		margin-top: 120px;

		@media (max-width:1500px) {
			margin-top: 80px;
		}

		.gmahde-img-bl {
			position: absolute;
			bottom: -20px;
			right: 0;

			@media (max-width:767px) {
				display: none;
			}

			img {
				width: 100%;
				max-width: 300px;

				@media (max-width:1500px) {
					max-width: 200px;
				}
			}
		}
	}

	.premium-service-bl {

		padding: 15px;
		border-radius: 11px;

		&.yellow {
			background: #DFAE12;
		}

		&.blue {
			background: #516870;
		}

		ul {
			background: #fff;
			border-radius: 11px;
			padding: 15px;
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			li {
				width: 20%;
				margin-bottom: 20px;

				@media (max-width:1200px) {
					width: 33%;
				}

				@media (max-width:767px) {
					width: 100%;
				}

				.service-list {
					text-align: center;
					padding: 0 25px;
					font-family: 'IBM Plex Sans';

					@media (max-width:1400px) {
						padding: 0 10px;
					}

					@media (max-width:991px) {
						padding: 0 15px;
					}

					.icon {
						display: inline-block;
						margin-bottom: 15px;
						min-height: 75px;
					}

					h2 {
						font-size: 18px;
						font-family: 'IBM Plex Sans';
						color: #002031;
						padding: 0 15px;

						@media (max-width:1400px) {
							font-size: 16px;
						}

						@media (max-width:1200px) {
							padding: 0;
						}
					}

					p {
						font-size: 16px;
						margin: 0;
						color: #002031;

						@media (max-width:1400px) {
							font-size: 14px;
						}
					}

					span {
						font-size: 12px;
						font-family: 'IBM Plex Sans';
					}
				}
			}
		}
	}

	.wirtschaften-sec {
		.heading-style {
			@media (max-width:767px) {
				text-align: left;
			}

		}

		.wirtschaften-content {
			padding-right: 380px;

			@media (max-width:991px) {
				padding-right: 140px;
			}

			@media (max-width:767px) {
				padding-right: 0;
			}
		}

		.wirtschaften-img-bl {
			position: absolute;
			right: 0;
			top: -130px;
			text-align: center;

			.img-first {
				margin-bottom: 70px;
				position: relative;

				img {
					max-width: 190px;

					@media (max-width:991px) {
						max-width: 140px;
					}
				}
			}

			.img-sec {
				position: relative;

				img {
					max-width: 320px;

					@media (max-width:991px) {
						max-width: 140px;
					}
				}
			}

			&:before {
				content: "";
				background: #DFAE12;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: -60px;
				width: 6px;
				height: 100%;
				z-index: 0;
			}

			@media (max-width:767px) {
				display: none
			}
		}
	}

	.mt-special {
		margin-top: -2%;
	}

	.fehlt-sec {
		.gmahde-img-bl {
			margin-bottom: -5px;
			position: relative;
			z-index: 1;

			@media (max-width:1200px) {
				margin-top: 20px;
			}

			@media (max-width:991px) {
				margin-top: 80px;
				display: inline-block;
				width: 230px;
			}

			@media (max-width:767px) {
				margin-top: 0;
				max-width: 300px;

			}

			img {
				width: 100%;
			}

		}

		.gmahde-img-bl2 {
			position: relative;
			z-index: 1;

			@media (max-width:1200px) {
				margin-top: 20px;
			}

			@media (max-width:991px) {
				margin-top: 80px;
				display: inline-block;
				width: 230px;
			}

			@media (max-width:767px) {
				margin-top: 0;
				max-width: 300px;

			}

			img {
				width: 100%;
			}

		}

		.heading-style {
			margin-top: 50px;

			.heading2 {
				margin-left: -105px;

				@media (max-width:767px) {
					margin-left: 0;
				}
			}

			span {
				margin-bottom: 20px;
				display: inline-block;
				padding: 0;
			}
		}
	}

	.author-contact-info {
		color: var(--link-font-color);
	}

	.feedback-sec {
		.feedback-intro-wraper {
			display: flex;
			justify-content: space-between;

			@media (max-width:767px) {
				flex-direction: column;
			}

			.feedback-intro {
				max-width: 40%;

				@media (max-width:767px) {
					max-width: 100%;
					width: 100%;
				}

				&:nth-child(2) {
					width: 33.33%;

					@media (max-width:767px) {
						width: 100%;
						display: flex;
						flex-direction: column-reverse;
					}
				}

				.feedback-rating {
					display: flex;
					align-items: center;
					text-align: right;
					justify-content: end;
					align-items: baseline;

					@media (max-width:991px) {
						flex-direction: column;
						justify-content: start;
						align-items: self-end;
					}

					@media (max-width:767px) {
						justify-content: start;
						align-items: start;
						text-align: left;
						margin-bottom: 30px;
					}

					p {
						font-size: 18px;
						margin: 0 15px 0 0;
						width: 200px;

						@media (max-width:1500px) {
							font-size: 16px;
							width: auto;
						}

						@media (max-width:991px) {
							margin: 0;
						}

					}

					img {
						width: 170px;
					}
				}
			}
		}

		.feedbakc-intro-img {
			max-width: 300px;
			position: absolute;
			bottom: -175px;
			left: 0;
			right: 0;
			margin: 0 auto;

			@media (max-width:1200px) {
				max-width: 200px;

			}

			@media (max-width:767px) {
				display: none;

			}

			img {
				width: 100%;
			}
		}

		.feedback-col {
			background: #516870;
			border-radius: 11px;
			padding: 15px;
			color: #fff;
			font-size: 13px;
			margin-top: 25px;
			text-align: center;

			h2 {
				color: #fff;
				font-size: 18px;
				line-height: 20px;
				margin: 0;
			}

			p {
				&:last-child {
					margin: 0;
				}
			}

			.sub-heading {
				color: #fff;
				font-size: 18px;
				font-weight: normal;

				.icon {
					width: 20px;
					display: inline-block;

					img {
						width: 100%
					}
				}
			}

			&.half {
				height: 200px;

				@media (max-width:1200px) {
					height: 230px;
				}

				@media (max-width:991px) {
					height: 300px;
				}

				@media (max-width:767px) {
					height: auto;
				}

				@media (max-width:1500px) {
					font-size: 16px;
					width: auto;
				}
			}

			&.one-third {
				height: 280px;

				@media (max-width:1200px) {
					height: 340px;
				}

				@media (max-width:991px) {
					height: 490px;
				}

				@media (max-width:767px) {
					height: auto;
				}
			}

			&.middle-feedback {
				margin-top: 174px;

				@media (max-width:767px) {
					margin-top: 25px;
				}
			}
		}
	}

	.kommen-sec {
		margin-top: 100px;

		.heading-style {
			.heading2 {
				margin: 0;
			}

			span {
				padding-left: 60px;
				margin-bottom: 20px;
				display: inline-block;

			}

			p {
				padding-left: 60px;
			}
		}



		ul {
			height: 920px;

			@media (max-width:767px) {
				height: auto;
				padding-left: 90px;
			}

			&:before {
				content: "";
				background-image: url(RESOURCE/img/Iconskommen.png);
				position: absolute;
				height: 790px;
				width: 100%;
				background-size: contain;
				left: 0;
				top: 8px;
				background-repeat: no-repeat;

				@media (max-width:767px) {
					content: "";
					background: #516870;
					position: absolute;
					height: 100%;
					width: 3px;
					background-size: contain;
					left: 30px;
					top: 0;
					background-repeat: no-repeat;
				}
			}

			li {
				position: relative;

				@media (max-width:767px) {
					margin-bottom: 50px;
				}

				&:before {
					@media (max-width:767px) {
						content: "";
						background: #516870;
						width: 26px;
						height: 2px;
						position: absolute;
						left: -33px;
						top: 11px;
					}
				}

				.kommen-block {
					position: absolute;
					max-width: 200px;

					@media (max-width:767px) {
						position: unset !important;
					}


					&:before {
						display: none;
					}

					h2 {
						font-size: 18px;
					}


				}

				&:nth-child(1) {
					&:after {
						@media (max-width:767px) {
							content: "";
							background-image: url(https://r.v-office.com/preview/v1983/1719396163000/img/icon_sr01.png);
							position: absolute;
							height: 52px;
							width: 52px;
							background-size: contain;
							left: -100px;
							top: -15px;
							background-repeat: no-repeat;
						}
					}


					.kommen-block {
						right: 250px;

						@media (max-width:1500px) {
							right: 180px;
							top: -41px;
						}

						@media (max-width:1199px) {
							right: 148px;
							top: -50px;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							height: 30px;
							width: 2px;
							right: 74px;
							bottom: -12px;

						}
					}
				}

				&:nth-child(2) {
					&:after {
						@media (max-width:767px) {
							content: "";
							background-image: url(https://r.v-office.com/preview/v1983/1719396163000/img/icon_sr02.png);
							position: absolute;
							height: 52px;
							width: 52px;
							background-size: contain;
							left: -100px;
							top: -15px;
							background-repeat: no-repeat;
						}
					}

					.kommen-block {
						right: 580px;
						top: 200px;

						@media (max-width:1500px) {
							right: 470px;
							top: 127px;
						}

						@media (max-width:1199px) {
							right: 380px;
							top: 70px;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							height: 30px;
							width: 2px;
							right: 95px;
							bottom: -51px;

						}
					}
				}

				&:nth-child(3) {
					&:after {
						@media (max-width:767px) {
							content: "";
							background-image: url(https://r.v-office.com/preview/v1983/1719396163000/img/icon_sr03.png);
							position: absolute;
							height: 52px;
							width: 52px;
							background-size: contain;
							left: -100px;
							top: -15px;
							background-repeat: no-repeat;
						}
					}

					.kommen-block {
						top: 530px;
						left: 120px;

						@media (max-width:1500px) {
							top: 650px;
						}

						@media (max-width:1199px) {
							top: 560px;
							left: 60px;
						}

						@media (max-width:991px) {
							top: 610px;
							left: 60px;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							height: 30px;
							width: 2px;
							right: 124px;
							bottom: -10px;

						}
					}
				}

				&:nth-child(4) {
					&:after {
						@media (max-width:767px) {
							content: "";
							background-image: url(https://r.v-office.com/preview/v1983/1719396163000/img/icon_sr04.png);
							position: absolute;
							height: 52px;
							width: 52px;
							background-size: contain;
							left: -100px;
							top: -15px;
							background-repeat: no-repeat;
						}
					}

					.kommen-block {
						right: -160px;
						left: auto;
						top: 160px;

						@media (max-width:1700px) {
							right: -80px;
							top: 200px;
						}

						@media (max-width:1199px) {
							right: -40px;
							top: 200px;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							height: 30px;
							width: 2px;
							left: 10px;
							top: -54px;

							@media (max-width:1500px) {
								left: 100px;
								top: -111px;
							}

							@media (max-width:1199px) {
								left: 140px;
								top: -124px;
							}

						}
					}
				}

				&:nth-child(5) {
					&:after {
						@media (max-width:767px) {
							content: "";
							background-image: url(https://r.v-office.com/preview/v1983/1719396163000/img/icon_sr05.png);
							position: absolute;
							height: 52px;
							width: 52px;
							background-size: contain;
							left: -100px;
							top: -15px;
							background-repeat: no-repeat;
						}
					}

					.kommen-block {

						right: 240px;
						top: 470px;

						@media (max-width:1500px) {
							right: 170px;
							top: 400px;
						}

						@media (max-width:1199px) {
							right: 170px;
							top: 330px;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							height: 30px;
							width: 2px;
							left: 0px;
							top: -49px;

						}
					}
				}

				&:nth-child(6) {
					&:after {
						@media (max-width:767px) {
							content: "";
							background-image: url(https://r.v-office.com/preview/v1983/1719396163000/img/icon_sr06.png);
							position: absolute;
							height: 52px;
							width: 52px;
							background-size: contain;
							left: -100px;
							top: -15px;
							background-repeat: no-repeat;
						}
					}

					.kommen-block {
						left: 420px;
						bottom: -876px;

						@media (max-width:1500px) {
							left: 345px;
							bottom: -766px;
						}

						@media (max-width:1199px) {
							left: 286px;
							bottom: -676px;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							height: 30px;
							width: 2px;
							left: 24px;
							top: -33px;

						}
					}
				}
			}
		}

		.kommen-img-bl {
			.img1 {
				position: absolute;
				width: 190px;

				@media (max-width:767px) {
					display: none;
				}

				img {
					width: 100%;
				}


				&:nth-child(1) {

					top: -190px;
					right: 60px;

					@media (max-width:991px) {
						bottom: 310px;
						right: 0px;
					}
				}

				&:nth-child(2) {

					top: 210px;
					left: 100px;

					@media (max-width:1199px) {
						top: 210px;
						left: 30px;
					}

					@media (max-width:991px) {
						top: 400px;
						left: 30px;
					}
				}

				&:nth-child(3) {
					bottom: 70px;
					right: 300px;

					@media (max-width:1199px) {
						bottom: 220px;
						right: 150px;
					}

					@media (max-width:991px) {
						bottom: 290px;
						right: -10px;
					}

				}
			}
		}
	}

	.vermieter-contact-sec {
		@media (max-width:767px) {
			margin-top: 50px;
		}

		.heading-style {
			margin-bottom: 30px;

			@media (max-width:767px) {
				padding-left: 0;
			}

			span {
				padding-left: 160px;

				@media (max-width:767px) {
					padding-left: 0;
				}
			}
		}

		.vermieter-form {
			margin: 0 -15px;

			@media (max-width:767px) {
				margin: 0;
			}

			.form-control {
				border-radius: 0;
				border-color: #000;
			}

			label {
				font-weight: 600;
			}

			.checkbox {
				label {
					margin-bottom: 5px;
				}
			}



			.btn-primary {
				background: #DFAE12;
				color: #fff;
				border: 0;

			}

			.btn-bl {
				text-align: right;

				@media (max-width:767px) {
					text-align: left;
				}
			}

		}

		.icon {
			max-width: 360px;
			padding-left: 60px;
			display: inline-block;
			margin-top: 80px;

			@media (max-width:991px) {
				display: none;
			}

			img {
				width: 100%;
			}
		}
	}

	.author-sec {
		.author-main-container {
			position: relative;
			padding-left: 400px;

			@media (max-width:991px) {
				padding-left: 230px;
			}

			@media (max-width:767px) {
				padding-left: 0;
			}

			.avtar {
				position: absolute;
				left: 0;
				max-width: 300px;

				@media (max-width:991px) {
					max-width: 200px;
				}

				@media (max-width:767px) {
					position: unset;
					margin-bottom: 30px;
				}

				img {
					width: 100%
				}


			}

			.author-info {
				.author-header {
					margin-bottom: 30px;

					.subheading {
						font-family: Open Sans;
						font-size: 18px;
						text-transform: uppercase;
						font-weight: 700;
					}

					h2 {
						font-family: 'Turbinado Pro';
						font-size: 45px;
						color: var(--heading-color);
						line-height: 40px;
					}
				}


				.author-content-main {
					display: flex;

					@media (max-width:767px) {
						flex-direction: column;
					}

					.author-content-left {
						padding-right: 100px;
						position: relative;

						@media (max-width:767px) {
							padding-bottom: 100px;
							padding-right: 0;
						}

						&:before {
							content: "";
							background: #516870;
							position: absolute;
							right: 60px;
							top: 0;
							width: 1px;
							height: 100%;

							@media (max-width:767px) {

								right: 0;
								top: auto;
								width: 100%;
								height: 1px;
								bottom: 50px;
							}
						}
					}

					.author-contact-info {
						label {
							font-family: 'Turbinado Pro';
							color: var(--heading-color);
							font-size: 33px;
							line-height: 30px;
							margin: 0;
						}

						a,
						p {
							font-weight: 700;
							font-size: 16px;
						}

					}

					.author-sign {
						max-width: 250px;

						img {
							width: 100%
						}
					}
				}
			}
		}
	}

}

.premium-serviceslider {

	/*.owl-nav {
		button {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 80px !important;
			margin-top: -30px;
			color: #002031 !important;

			&:before {
				content: "";
				border: solid #002031;
				border-width: 0 3px 3px 0;
				display: inline-block;
				padding: 6px;
			}

			span {
				display: none;
			}

			@media (max-width: 767px) {
				margin-top: 0;
				font-size: 40px !important;

			}

			&.owl-prev {
				left: 30px;

				&:before {
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
				}

				@media (max-width: 767px) {
					left: 15px;

				}
			}

			&.owl-next {
				right: 30px;

				&:before {
					transform: rotate(-45deg);
					-webkit-transform: rotate(-45deg);
				}

				@media (max-width: 767px) {
					right: 15px;

				}

			}
		}
	}*/
	.owl-dots {
		text-align: center;
		width: 100%;


		.owl-dot {
			background: var(--color-primary);
			width: 10px;
			height: 10px;
			border-radius: 100%;
			margin: 0 3px;

			&.active {
				background: #dfae12;
			}
		}

	}
}